import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-base-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './base-button.component.html',
  styleUrls: ['./base-button.component.scss'],
})
export class BaseButtonComponent {
  @Input() kind: 'filled' | 'outlined' | 'link' = 'link';
  @Input() disabled: boolean = false;

  get classNames() {
    return {
      [this.kind]: this.kind,
      'disabled': this.disabled,
    };
  }
}
